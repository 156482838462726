import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'

const IndexPage = () => {
  const cwvLcp = '../images/cwv-lcp.svg'
  const cwvFid = '../images/cwv-fid.svg'
  const cwvCls = '../images/cwv-cls.svg'
  const lcpCnn = '../images/lcp-cnn.avif'
  const fidExample = '../images/fid-example.svg'
  const clsExample = '../images/cls-example.avif'

  return (
    <Layout title="Performance">
      <h1>Performance</h1>
      <p className="usa-intro">
        Get a measurable indicator of application performance by considering Web
        Vitals.
      </p>
      <p>
        <a
          href="https://web.dev/vitals/"
          target="_blank"
          className="usa-link"
          rel="noreferrer"
        >
          Web Vitals
        </a>{' '}
        are a set of metrics and factors that Google has assembled as indicators
        of a good user experience when using web applications.
      </p>
      <p>
        Although there are different types of performance and each has its own underlying drivers,
        overall performance can be measured holistically using these indicators.
      </p>
      <p>
        As a simple example, there are several techniques to cut down on image
        load time. You could use smaller images, higher compression, lower
        quality, geographic Content Delivery Network (CDN) edge caching, etc. Regardless of solution, image load time is the
        indicator being measured.
      </p>
      <p>Some examples of the set of Web Vitals include:</p>
      <ul className="usa-list">
        <li>
          <a
            href="https://web.dev/ttfb/"
            target="_blank"
            rel="noreferrer"
            className="usa-link"
          >
            Time to First Byte (TTFB)
          </a>
        </li>
        <li>
          <a
            href="https://web.dev/fcp/"
            target="_blank"
            rel="noreferrer"
            className="usa-link"
          >
            First Contentful Paint (FCP)
          </a>
        </li>
        <li>
          <a
            href="https://web.dev/lcp/"
            target="_blank"
            rel="noreferrer"
            className="usa-link"
          >
            Largest Contentful Paint (LCP)
          </a>
        </li>
        <li>
          <a
            href="https://web.dev/tbt/"
            target="_blank"
            rel="noreferrer"
            className="usa-link"
          >
            Total Blocking Time (TBT)
          </a>
        </li>
        <li>
          <a
            href="https://web.dev/cls/"
            target="_blank"
            rel="noreferrer"
            className="usa-link"
          >
            Cumulative Layout Shift (CLS)
          </a>
        </li>
      </ul>
      <p>
        Those are great, but not all of them are measurable in the field, and
        not all of them directly reflect the end user experience.
      </p>
      <h2 id="cwv">Core Web Vitals</h2>
      <p>
        In order to pare the list of Web Vitals metrics down into those that are
        both measurable in the field and directly relevant to the end user
        experience, Google has designated a subset of these as&nbsp;
        <a
          href="https://web.dev/vitals/#core-web-vitals"
          className="usa-link"
          target="_blank"
          rel="noreferrer"
        >
          Core Web Vitals
        </a>
        .
      </p>
      <p>
        These core indicators are intended to act as a relatively stable and
        standard set for developers to use to measure their end user experience.
        In order to stay relevant, but not disruptive, Google reevaluates the
        set of Core Web Vitals annually.
      </p>
      <p>
        The current set for 2020 focuses on three aspects of the user
        experience—loading, interactivity, and visual stability—and includes the
        following metrics (and their respective thresholds):
      </p>
      <div className="grid-container">
        <div className="grid-row">
          <div className="tablet:grid-col">
            <StaticImage
              alt="Largest Contentful Paint threshold recommendations"
              placeholder="none"
              src={cwvLcp}
            />
          </div>
          <div className="tablet:grid-col">
            <StaticImage
              alt="First Input Delay threshold recommendations"
              placeholder="none"
              src={cwvFid}
            />
          </div>
          <div className="tablet:grid-col">
            <StaticImage
              alt="Cumulative Layout Shift Paint threshold recommendations"
              placeholder="none"
              src={cwvCls}
            />
          </div>
        </div>
      </div>
      <ul className="usa-list">
        <li>
          <a
            href="https://web.dev/lcp/"
            target="_blank"
            rel="noreferrer"
            className="usa-link"
          >
            Largest Contentful Paint (LCP)
          </a>
          : measures loading performance. To provide a good user experience, LCP
          should occur within 2.5 seconds of when the page first starts loading.
        </li>
        <li>
          <a
            href="https://web.dev/fid/"
            target="_blank"
            rel="noreferrer"
            className="usa-link"
          >
            First Input Delay (FID)
          </a>
          : measures interactivity. To provide a good user experience, pages
          should have a FID of 100 milliseconds or less.
        </li>
        <li>
          <a
            href="https://web.dev/cls/"
            target="_blank"
            rel="noreferrer"
            className="usa-link"
          >
            Cumulative Layout Shift (CLS)
          </a>
          : measures visual stability. To provide a good user experience, pages
          should maintain a CLS of 0.1 or less.
        </li>
      </ul>
      <p>
        For each of the above metrics, to ensure you&apos;re hitting the
        recommended target for most of your users, Google recommends that a good
        threshold to measure is the 75th percentile of page loads, segmented
        across mobile and desktop devices.
      </p>
      <h2 id="lcp">Largest Contentful Paint</h2>
      <p>
        The Largest Contentful Paint (LCP) metric reports the render time of the
        largest image or text block visible within the viewport, relative to
        when the page first started loading. To provide a good user experience,
        LCP should occur within <strong>2.5 seconds</strong> of when the page
        first starts loading.
      </p>
      <p>
        Historically, it&apos;s been a challenge for web developers to measure
        how quickly the main content of a web page loads and is visible to
        users. Measuring how long it takes to load the code doesn&apos;t
        necessarily translate to when users see content on their screen. By
        focusing on the user experience, and supported by research done at World Wide 
        Web Consortium (W3C) and Google, a good measure is to look at when 
        the largest element in the viewport was rendered.
      </p>
      <p>
        As an example, below the first content paint occurs when the menu is
        rendered, but the largest content paint occurs when the headline image
        renders:
      </p>
      <StaticImage src={lcpCnn} alt="Largest Contentful Paint timeline from cnn.com" placeholder="none"/>
      <h3>What is considered in LCP?</h3>
      <p>
        Not all page elements are meaningful when considering candidates for
        largest contentful paint. Only the following types of elements are
        considered:
      </p>
      <ul className="usa-list">
        <li>
          <code>&lt;img&gt;</code> elements
        </li>
        <li>
          <code>&lt;image&gt;</code> elements inside an <code>&lt;svg&gt;</code>{' '}
          element
        </li>
        <li>
          <code>&lt;video&gt;</code> elements (the poster image is used)
        </li>
        <li>
          An element with a background image loaded via the <code>url()</code>{' '}
          function (as opposed to a CSS gradient)
        </li>
        <li>
          Block-level elements containing text nodes or other inline-level text
          elements children.
        </li>
      </ul>
      <p>
        For full details on LCP, refer to the{' '}
        <a
          href="https://w3c.github.io/largest-contentful-paint/"
          target="_blank"
          rel="noreferrer"
        >
          Largest Contentful Paint API
        </a>{' '}
        or the Google web.dev article about{' '}
        <a href="https://web.dev/lcp/" target="_blank" rel="noreferrer">
          LCP
        </a>
        .
      </p>
      <h3>How to improve LCP</h3>
      <p>LCP is primarily affected by four factors:</p>
      <ul className="usa-list">
        <li>Slow server response times</li>
        <li>Render-blocking JavaScript and CSS</li>
        <li>Resource load times</li>
        <li>Client-side rendering</li>
      </ul>
      <p>
        Simple steps to improve LCP include optimization of static content (CSS,
        Images, Fonts, and JavaScipt), optimizing the Critical Rendering Path,
        and employing the{' '}
        <a
          href="https://web.dev/apply-instant-loading-with-prpl/"
          target="_blank"
          rel="noreferrer"
        >
          PRPL
        </a>{' '}
        pattern.
      </p>
      <p>
        For more extensive resources for optimizing LCP, refer to the web.dev
        article about{' '}
        <a
          href="https://web.dev/optimize-lcp/"
          target="_blank"
          rel="noreferrer"
        >
          Optimizing LCP
        </a>
        .
      </p>
      <h2 id="fid">First Input Delay</h2>
      <p>
        First Input Delay (FID) measures the time from when a user first
        interacts with a page (i.e. when they click a link, tap on a button, or
        use a custom, JavaScript-powered control) to the time when the browser
        is actually able to begin processing event handlers in response to that
        interaction. To provide a good user experience, sites should strive to
        have a First Input Delay of <strong>100 milliseconds</strong> or less.
      </p>
      <p>
        It&apos;s one thing to have a site that renders quickly, but if the user
        can&apos;t interact with it because the browser is still busy doing
        other things, that&apos;s not great. For example, if your application
        executes a large chunk of JavaScript on load, event listeners and
        element actions may fail to run because the main thread is busy doing
        other things.
      </p>
      <p>
        Consider the following example. The graphic shows a page that&apos;s
        making a couple of network requests for resources (most likely CSS and
        JS files), and — after those resources are finished downloading —
        they&apos;re processed on the main thread. This results in periods where
        the main thread is momentarily busy, which is indicated by the main
        thread task blocks.
      </p>
      <p>
        Observe what would happen if a user tried to interact with a page near
        the beginning of the longest loading task:
      </p>
      <StaticImage
        src={fidExample}
        placeholder="none"
        alt="Example page load trace with FCP, TTI, and FID"
      />
      <p>
        Because the input occurs while the browser is in the middle of running a
        task, it has to wait until the task completes before it can respond to
        the input. The time it must wait is the FID value for this user on this
        page.
      </p>
      <p>
        For full details on First Input Delay, refer to the Google web.dev
        article about{' '}
        <a href="https://web.dev/fid/" target="_blank" rel="noreferrer">
          FID
        </a>
        .
      </p>
      <h3>How to improve FID</h3>
      <p>
        FID is primarily caused by heavy JavaScript execution on loading.
        Optimizing how JavaScript parses, compiles, and executes on your web
        page will directly reduce FID. Steps to improve this include:
      </p>
      <ul className="usa-list">
        <li>Break up long tasks</li>
        <li>Optimize your page for interaction readiness</li>
        <li>Use a web worker</li>
        <li>Reduce JavaScript execution time</li>
      </ul>
      <p>
        For a more extensive deep-dive on optimizing FID, refer to the web.dev
        article about{' '}
        <a
          href="https://web.dev/optimize-fid/"
          target="_blank"
          rel="noreferrer"
        >
          Optimizing FID
        </a>
        .
      </p>
      <h2 id="cls">Cumulative Layout Shift</h2>
      <p>
        Layout Shift is what you experience when you&apos;re trying to click on
        something and, all of a sudden, an advertisement loads and the whole
        page shifts down to accommodate. It can simply be annoying, or it can be
        damaging - errant clicks taking you to places you don&apos;t want to go,
        submitting things you don&apos;t want to submit, and so on.
      </p>
      <p></p>
      <StaticImage
        src={clsExample}
        placeholder="none"
        alt="Layout shift example with stable and unstable elements and viewport clipping"
      />

      <p>
        Cumulative Layout Shift (CLS) is a measure of the largest burst of
        layout shift scores for every unexpected layout shift that occurs during
        the entire lifespan of a page. To provide a good user experience, sites
        should strive to have a CLS score of <strong>0.1</strong> or less.
      </p>
      <p>
        A layout shift occurs any time a visible element changes its position
        from one rendered frame to the next. (See below for details on how
        individual layout shift scores are calculated.)
      </p>
      <p>
        A burst of layout shifts, known as a session window, is when one or more
        individual layout shifts occur in rapid succession with less than
        1-second in between each shift and a maximum of 5 seconds for the total
        window duration.
      </p>
      <p>
        The largest burst is the session window with the maximum cumulative
        score of all layout shifts within that window.
      </p>
      <p>
        For full details on Cumulative Layout Shift, refer to the Google web.dev
        article about{' '}
        <a
          href="https://web.dev/cls/"
          target="_blank"
          rel="noreferrer"
          className="usa-link"
        >
          CLS
        </a>
        .
      </p>
      <h3>How to improve CLS</h3>
      <p>
        CLS is typically caused by asynchronous content being loaded and rendered
        after other elements have already been rendered. For most cases, you can
        avoid all unexpected layout shifts by sticking to a few guiding
        principles:
      </p>
      <ul className='usa-list'>
        <li className='usa-list__item'>
          <strong>Always include size attributes on your images and video elements, or
            otherwise reserve the required space with something like{' '}
            <a href="https://css-tricks.com/aspect-ratio-boxes/" rel="noreferrer" target="_blank">
              CSS aspect ratio boxes
            </a>
            .
          </strong>{' '}
          This approach ensures that the browser can allocate the correct amount
          of space in the document while the image is loading. Note that you can
          also use the{' '}
          <a
            href="https://github.com/w3c/webappsec-feature-policy/blob/master/policies/unsized-media.md"
            rel="noreferrer"
            target="_blank"
          >
            unsized-media feature policy
          </a>{' '}
          to force this behavior in browsers that support feature policies.
        </li>
        <li className='usa-list__item'>
          <strong>
            Never insert content above existing content, except in response to a
            user interaction.
          </strong>{' '}
          This ensures any layout shifts that occur are expected.
        </li>
        <li className='usa-list__item'>
          <strong>
            Prefer transform animations to animations of properties that trigger
            layout changes.
          </strong>{' '}
          Animate transitions in a way that provides context and continuity from
          state to state.
        </li>
      </ul>
      <p>For a deep dive on how to improve CLS, refer to the Google web.dev
        article about <a href="https://web.dev/optimize-cls/" target="_blank" rel="noreferrer">Optimizing CLS</a>.</p>
    </Layout>
  )
}

export default IndexPage
